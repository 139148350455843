var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{ref:"loader",staticClass:"case-study"},[(_vm.blocks)?_c('div',[_c('BlockWave',_vm._b({},'BlockWave',{
        enabled: true,
        position: {
          x: 25,
          y: -33
        },
        color: _vm.$colours['waveBlue'],
        rotate: 45,
        alignment: {
          x: 'left'
        }
      },false),[_c('BlockWave',_vm._b({on:{"mask":function($event){_vm.heroMask = $event}}},'BlockWave',{
          enabled: true,
          position: {
            x: 150,
            y: 150
          },
          color: _vm.$colours['waveBlue'],
          rotate: 180,
          alignment: {
            x: 'right'
          }
        },false),[_c('BlockHero',{attrs:{"block":_vm.hero}})],1)],1),_c('BlockWave',{attrs:{"enabled":true,"color":_vm.$colours['waveBlue'],"maskData":_vm.heroMask}},[_c('BlockWave',_vm._b({},'BlockWave',_vm.waveApiToComponentCoupler(_vm.content.wave['1']),false),[_c('BlockWave',_vm._b({},'BlockWave',_vm.waveApiToComponentCoupler(_vm.content.wave['2']),false),[_c('div',{staticClass:"case-study__blocks"},_vm._l((_vm.blocks),function(block){return _c('div',{key:block.id,class:`block-${block.type.toLowerCase()}-wrapper`},[(block.type === 'Card')?_c('BlockCard',{attrs:{"block":block}}):_vm._e(),(block.type === 'Quote')?_c('BlockQuote',{attrs:{"block":block}}):_vm._e()],1)}),0)])],1)],1),_c('BlockWave',_vm._b({},'BlockWave',{
        enabled: true,
        type: 4,
        position: {
          x: 0,
          y: 0
        },
        color: _vm.$colours['waveLightBlue']
      },false),[_c('BlockStats',{attrs:{"block":_vm.content.statisticGroup,"prev":_vm.content.previousCaseStudy,"next":_vm.content.nextCaseStudy}})],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }