<template>
  <section class="block-card">
    <div class="block-card__container">
      <div class="block-card__media">
        <div class="block-card__media__container">
          <img
            ref="img"
            v-if="image"
            :src="image.src"
            :srcset="image.srcset"
            :alt="image.title"
          />
          <div v-if="media">
            <img
              v-for="image in media.images"
              :key="image.title"
              :src="image.src"
              :srcset="image.srcset"
              :alt="image.title"
            />
          </div>
        </div>
      </div>
      <div class="block-card__content">
        <div class="block-card__content__container">
          <div class="block-card__content__icon" v-if="block.icon">
            <img :src="block.icon" />
          </div>
          <h3>{{ block.heading }}</h3>
          <div class="block-card__content__copy">
            <div v-html="block.body"></div>
            <ul v-if="block.pictograms" class="pictograms">
              <li
                v-for="pictogram in block.pictograms"
                :key="pictogram.id"
                class="pictogram"
              >
                <img
                  ref="img"
                  v-if="pictogram.icon"
                  :src="pictogram.icon.src"
                  :srcset="pictogram.icon.srcset"
                  :alt="pictogram.icon.title"
                />
                <span>{{ pictogram.text }}</span>
              </li>
            </ul>
            <div class="block-card__content__copy__cta">
              <CtaItem
                v-if="cta"
                :link="`${cta.link}`"
                :colour="cta.colour"
                :label="cta.label"
                :bubble="cta.bubble"
                :disabled="cta.disabled"
                :disableIcon="cta.disableIcon"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="block-card__divider">
      <div class="block-card__divider__line"></div>
    </div>
  </section>
</template>

<script>
import { bind, unbind } from '@/utils/eventListener';
import CtaItem from '@/components/Cta/CtaItem';

export default {
  name: 'BlockCard',
  components: {
    CtaItem
  },
  props: {
    block: [Array, Object]
  },
  computed: {
    image() {
      return this.block.media.images ? this.block.media.images[0] : null;
    },
    cta() {
      return this.block.ctaGroup ? this.block.ctaGroup[0] : null;
    }
  },

  mounted() {
    const { img } = this.$refs;

    if (img.complete) {
      this.$emit('loaded');
    } else {
      bind(img, 'load', this.handleLoad);
    }
  },

  beforeDestroy() {
    const { img } = this.$refs;
    unbind(img, 'load', this.handleLoad);
  },

  methods: {
    handleLoad() {
      this.$emit('loaded');
    }
  }
};
</script>

<style lang="postcss">
.block-card {
  @apply max-w-max mx-auto;
  &__container {
    @apply flex flex-col-reverse justify-center;
    @screen sm {
      @apply flex-row flex-wrap;
    }
  }
  &__media {
    @apply z-40;
    @apply flex;
    @screen sm {
      @apply w-1/2;
    }
  }
  &__content {
    @apply z-40;
    @apply flex;
    @apply text-center;
    @apply w-full;
    @screen sm {
      @apply text-left;
      @apply w-1/2;
    }
    &__icon {
      @apply py-8;
      img {
        @apply mx-auto;
        width: 80px;
        @screen sm {
          @apply mx-0;
          width: 100px;
        }
      }
    }
    h3 {
      @apply font-ginto-regular;
      @apply py-8;
      @apply text-left;
      font-size: calc(28px + (40 - 28) * (100vw - 640px) / (1920 - 640));
      @screen sm {
        @apply pt-0;
      }
    }
    &__copy {
      @apply font-ginto-regular;
      font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
      p {
        @apply text-left;
        @apply m-4;
      }
      ul {
        @apply list-disc;
        @apply mt-8 ml-8;
        @apply text-left;
      }
      @screen sm {
        @apply max-w-3xl;
      }
      &__cta {
        @apply mt-16;
        @apply flex justify-center;
        @screen sm {
          @apply justify-start;
        }
      }
    }
  }
  &__divider {
    @apply flex;
    @apply w-full;
    height: 60px;
    @apply mb-16;
    @screen sm {
      height: 125px;
      @apply my-40;
    }
    &__line {
      height: 60px;
      @apply bg-brand-darkerBlue;
      @apply h-full;
      width: 1px;
      @apply mx-auto;
      @screen sm {
        height: 125px;
      }
    }
  }

  ul.pictograms {
    margin: 40px 0;
    display: flex;
    align-items: flex-start;
    gap: 40px;
    li {
      list-style: none;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      text-align: center;
    }
    img {
      aspect-ratio: 1/1;
      height: clamp(50px, 10rem, 100px);
    }
  }
}

@media screen and (min-width: 640px) {
  .blocks {
    .block-card-wrapper {
      &:nth-child(even) {
        .block-card__content {
          padding-right: 20px;
        }
      }
      &:nth-child(odd) {
        .block-card__content {
          padding-left: 20px;
        }
      }
    }
  }
}
</style>
