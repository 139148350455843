var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{ref:"loader",class:[_vm.backgroundColour]},[_c('div',[(_vm.content)?_c('div',{staticClass:"blocks"},[_vm._l((_vm.content),function(block,i){return [(block.type === 'hero')?_c('BlockMultiWave',{key:block.id,attrs:{"block":block,"lastMask":_vm.getMask(i - 1)},on:{"mask":function($event){return _vm.setMask(i, $event)}}},[_c('div',{class:`block-${block.type}-wrapper`},[_c('BlockHero',{attrs:{"block":block}})],1)]):_vm._e(),(block.type === 'text')?_c('BlockMultiWave',{key:block.id,attrs:{"block":block,"lastMask":_vm.getMask(i - 1)},on:{"mask":function($event){return _vm.setMask(i, $event)}}},[_c('div',{class:`block-${block.type}-wrapper`},[_c('BlockText',{class:[{ 'remove-spacing': _vm.blockRemoveSpacing && i < 2 }],attrs:{"block":block}})],1)]):_vm._e(),(block.type === 'paragraphs')?_c('BlockMultiWave',{key:block.id,attrs:{"block":block,"lastMask":_vm.getMask(i - 1)},on:{"mask":function($event){return _vm.setMask(i, $event)}}},[_c('div',{class:`block-${block.type}-wrapper`},[_c('BlockParagraphs',{attrs:{"block":block}})],1)]):_vm._e(),(block.type === 'video')?_c('BlockMultiWave',{key:block.id,attrs:{"block":block,"lastMask":_vm.getMask(i - 1)},on:{"mask":function($event){return _vm.setMask(i, $event)}}},[_c('div',{class:`block-${block.type}-wrapper`},[_c('BlockVideo',_vm._b({attrs:{"block":block}},'BlockVideo',block,false))],1)]):_vm._e(),(block.type === 'caseStudy')?_c('BlockMultiWave',{key:block.id,attrs:{"block":block,"lastMask":_vm.getMask(i - 1)},on:{"mask":function($event){return _vm.setMask(i, $event)}}},[_c('div',{class:`block-${block.type}-wrapper`},[_c('BlockCase',{attrs:{"block":block}})],1)]):_vm._e(),(block.type === 'split')?_c('BlockMultiWave',{key:block.id,attrs:{"block":block,"lastMask":_vm.getMask(i - 1)},on:{"mask":function($event){return _vm.setMask(i, $event)}}},[_c('div',{class:`block-${block.type}-wrapper anchored`,attrs:{"id":block.title
                ? block.title.toLowerCase().replaceAll(' ', '-')
                : null}},[_c('BlockSplit',{attrs:{"block":block}})],1)]):_vm._e(),(block.type === 'card')?_c('BlockMultiWave',{key:block.id,class:`block-${block.type}-wrapper`,attrs:{"block":block,"lastMask":_vm.getMask(i - 1)},on:{"mask":function($event){return _vm.setMask(i, $event)}}},[_c('div',{staticClass:"block-card-wrapper__inner"},[_c('BlockCard',{attrs:{"block":block},on:{"loaded":function($event){return _vm.update()}}})],1)]):_vm._e(),(block.type === 'carousel')?_c('BlockMultiWave',{key:block.id,attrs:{"block":block,"lastMask":_vm.getMask(i - 1)},on:{"mask":function($event){return _vm.setMask(i, $event)}}},[_c('div',{class:`block-${block.type}-wrapper anchored`,attrs:{"id":block.title
                ? block.title.toLowerCase().replaceAll(' ', '-')
                : null}},[_c('BlockCarousel',{attrs:{"block":block},on:{"ready":function($event){return _vm.update()}}})],1)]):_vm._e(),(block.type === 'list')?_c('BlockMultiWave',{key:block.id,attrs:{"block":block,"lastMask":_vm.getMask(i - 1)},on:{"mask":function($event){return _vm.setMask(i, $event)}}},[_c('div',{class:`block-${block.type}-wrapper anchored`,attrs:{"id":block.heading
                ? block.heading.toLowerCase().replaceAll(' ', '-')
                : null}},[_c('BlockList',{attrs:{"block":block}})],1)]):_vm._e(),(block.type === 'options')?_c('BlockMultiWave',{key:block.id,attrs:{"block":block,"lastMask":_vm.getMask(i - 1)},on:{"mask":function($event){return _vm.setMask(i, $event)}}},[_c('div',{class:`block-${block.type}-wrapper`},[_c('BlockOptionCards',{attrs:{"block":block}})],1)]):_vm._e(),(block.type === 'cardEntries')?_c('BlockMultiWave',{key:block.id,attrs:{"block":block,"lastMask":_vm.getMask(i - 1)},on:{"mask":function($event){return _vm.setMask(i, $event)}}},[_c('div',{class:`block-${block.type}-wrapper`},[_c('BlockCardEntries',{attrs:{"block":block}})],1)]):_vm._e(),(block.type === 'person')?_c('BlockMultiWave',{key:block.id,attrs:{"block":block,"lastMask":_vm.getMask(i - 1)},on:{"mask":function($event){return _vm.setMask(i, $event)}}},[_c('div',{class:`block-${block.type}-wrapper`},[_c('BlockPerson',{attrs:{"block":block}})],1)]):_vm._e(),(block.type === 'embed')?_c('BlockMultiWave',{key:block.id,attrs:{"block":block,"lastMask":_vm.getMask(i - 1)},on:{"mask":function($event){return _vm.setMask(i, $event)}}},[_c('div',{class:`block-${block.type}-wrapper`,attrs:{"id":block.title
                ? block.title.toLowerCase().replaceAll(' ', '-')
                : null}},[_c('BlockEmbed',{attrs:{"block":block}})],1)]):_vm._e(),(block.type === 'plain')?_c('BlockMultiWave',{key:block.id,attrs:{"block":block,"lastMask":_vm.getMask(i - 1)},on:{"mask":function($event){return _vm.setMask(i, $event)}}},[_c('div',{class:`block-${block.type}-wrapper`},[_c('BlockPlain',{attrs:{"block":block}})],1)]):_vm._e(),(block.type === 'steps')?_c('BlockMultiWave',{key:block.id,attrs:{"block":block,"lastMask":_vm.getMask(i - 1)},on:{"mask":function($event){return _vm.setMask(i, $event)}}},[_c('div',{class:`block-${block.type}-wrapper`},[_c('BlockSteps',{attrs:{"block":block}})],1)]):_vm._e(),(block.type === 'details')?_c('BlockMultiWave',{key:block.id,attrs:{"block":block,"lastMask":_vm.getMask(i - 1)},on:{"mask":function($event){return _vm.setMask(i, $event)}}},[_c('div',{class:`block-${block.type}-wrapper`},[_c('BlockDetails',{attrs:{"block":block}})],1)]):_vm._e(),(block.type === 'callToAction')?_c('BlockMultiWave',{key:block.id,attrs:{"block":block,"lastMask":_vm.getMask(i - 1)},on:{"mask":function($event){return _vm.setMask(i, $event)}}},[_c('div',{class:`block-${block.type}-wrapper`},[_c('BlockCta',{attrs:{"block":block}})],1)]):_vm._e(),(block.type === 'form')?_c('BlockMultiWave',{key:block.id,attrs:{"block":block,"lastMask":_vm.getMask(i - 1)},on:{"mask":function($event){return _vm.setMask(i, $event)}}},[_c('div',{class:`block-${block.type}-wrapper`},[_c('BlockForm',{attrs:{"block":block}})],1)]):_vm._e()]})],2):_vm._e(),_c('div',{staticClass:"cookiebot"},[_c('div',{staticClass:"cookiebot__container"},[_c('div',{ref:"cookiebotscript"})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }