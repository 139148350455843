<template>
  <section class="block-form" :class="[background]">
    <div id="mc_embed_shell">
      <div id="mc_embed_signup" class="block-form__container">
        <h2>Sign up to our newsletter</h2>
        <form
          action="https://grosvenor.us14.list-manage.com/subscribe/post?u=9117b6df3241ea4cdc13df0c9&amp;id=c36d00a6e4&amp;f_id=008155e0f0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          class="validate"
          target="_blank"
        >
          <div id="mc_embed_signup_scroll">
            <div class="indicates-required">
              <span class="asterisk">*</span> indicates required
            </div>
            <div class="form__fields">
              <div class="field mc-field-group">
                <label for="mce-EMAIL" class="field__label"
                  >Email Address <span class="asterisk">*</span></label
                ><input
                  type="email"
                  name="EMAIL"
                  class="field__input"
                  id="mce-EMAIL"
                  :placeholder="'EMAIL'"
                  required=""
                  value=""
                /><span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span>
              </div>
              <div class="field mc-field-group">
                <label for="mce-FNAME" class="field__label">First Name </label
                ><input
                  type="text"
                  name="FNAME"
                  class="field__input"
                  id="mce-FNAME"
                  :placeholder="'NAME'"
                  value=""
                />
              </div>
              <div class="field mc-field-group">
                <label for="mce-LNAME" class="field__label">Last Name </label
                ><input
                  type="text"
                  name="LNAME"
                  class="field__input"
                  id="mce-LNAME"
                  :placeholder="'LAST NAME'"
                  value=""
                />
              </div>
            </div>

            <div id="mce-responses" class="clear">
              <div
                class="response"
                id="mce-error-response"
                style="display: none"
              ></div>

              <div
                class="response"
                id="mce-success-response"
                style="display: none"
              ></div>
            </div>
            <div aria-hidden="true" style="position: absolute; left: -5000px">
              <input
                type="text"
                name="b_9117b6df3241ea4cdc13df0c9_c36d00a6e4"
                tabindex="-1"
                value=""
              />
            </div>
          </div>
          <ButtonSubmit :colour="'darkerBlue'" :label="'Sign up'" />
        </form>
      </div>
      <Script
        type="text/javascript"
        src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
      >
      </Script>
      <Script type="text/javascript">
        ;(function ($) { window.fnames = new Array() window.ftypes = new Array()
        fnames[0] = 'EMAIL' ftypes[0] = 'email' fnames[1] = 'FNAME' ftypes[1] =
        'text' fnames[2] = 'LNAME' ftypes[2] = 'text' fnames[3] = 'ADDRESS'
        ftypes[3] = 'address' fnames[4] = 'PHONE' ftypes[4] = 'phone' })(jQuery)
        var $mcj = jQuery.noConflict(true)
      </Script>
    </div>
  </section>
</template>

<script>
import 'intersection-observer';
import { background } from '@/mixins';
import ButtonSubmit from '@/components/Button/ButtonSubmit';
export default {
  name: 'BlockForm',
  components: {
    ButtonSubmit
  },
  props: {
    block: [Array, Object]
  },
  mixins: [background],
  data() {
    return {
      intersectionOptions: {
        root: null,
        threshold: 0.5
      },
      observer: null,
      intersected: false
    };
  },
  methods: {
    intersectionCallback(entries) {
      const entry = entries[0];
      if (entry.intersectionRatio > 0) {
        this.observer.unobserve(entry.target);
        this.intersected = true;
      }
    },
    initObserver() {
      this.observer = new IntersectionObserver(
        this.intersectionCallback,
        this.intersectionOptions
      );
      this.observer.observe(this.$el);
    }
  },
  mounted() {
    this.initObserver();
  }
};
</script>

<style lang="postcss">
@import '../../styles/animate.css';

.block-form {
  @apply py-24;
  position: relative;
  &__container {
    @apply w-9/10;
    display: flex;
    flex-direction: column;
    gap: 40px;
    @apply max-w-screen-xl mx-auto;
    @apply pb-24;
  }
  h2 {
    @apply font-ginto-black;
    font-size: 38px;
  }
}
.form {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  width: 100%;
}

.form__fields {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  @screen lg {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
.field__label {
  display: none;
}
.field__input {
  padding: 16px 20px;
  border-radius: 4px;
  background-color: white;
  @apply border-brand-blue border;
  width: 100%;
  height: 100%;
  display: block;
  font-size: 16px;
}
</style>
