var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{ref:"header",staticClass:"core-header",class:[_vm.getIsMenuOpen ? 'open' : 'close']},[_c('div',{ref:"headerbar",staticClass:"core-header__bar"},[_c('div',{staticClass:"core-header__bar__container"},[_c('button',{staticClass:"core-header__bar__burger-menu",on:{"click":_vm.toggleMenu}},[_c('IconBurgerMenu')],1),_c('div',{staticClass:"core-header__bar__logo"},[_c('LogoEmblem')],1)])]),_c('div',{staticClass:"core-header__content",style:(_vm.openHeight)},[_c('div',{staticClass:"core-header__content__wrapper"},[_c('div',{staticClass:"core-header__content__container"},[_c('nav',{ref:"primaryMenu",staticClass:"primaryMenu",style:([_vm.navStyles, _vm.navTransform])},[_c('div',{staticClass:"logo"},[_c('router-link',{staticClass:"h-full w-full",attrs:{"to":"/"}},[_c('LogoNav')],1)],1),_c('ul',[_c('div',{staticClass:"left"},[_c('li',{staticStyle:{"animation-delay":"0.2s"}},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")])],1),(_vm.getIsMobile)?_c('li',{staticStyle:{"animation-delay":"0.3s"}},[_c('router-link',{attrs:{"to":"/about-us"}},[_vm._v(" About us ")])],1):_c('li',{on:{"click":function($event){_vm.toggleSubMenu();
                  _vm.aboutus = !_vm.aboutus;}}},[_c('router-link',{attrs:{"to":"/about-us"}},[_vm._v(" About us ")])],1),_c('li',{staticStyle:{"animation-delay":"0.4s"},on:{"click":function($event){_vm.toggleSubMenu();
                  _vm.grantmaking = !_vm.grantmaking;}}},[(_vm.getIsMobile)?_c('span',[_vm._v("Grantmaking")]):_c('router-link',{attrs:{"to":"/grantmaking"}},[_vm._v(" Grantmaking ")]),_c('div',{staticClass:"icon"},[_c('IconArrowMenu',{attrs:{"direction":"next"}})],1)],1),_c('li',{staticStyle:{"animation-delay":"0.5s"}},[_c('router-link',{attrs:{"to":"/success-stories"}},[_vm._v("Success Stories")])],1)]),_c('div',{staticClass:"right"},[_c('li',{staticStyle:{"animation-delay":"0.6s"}},[_c('router-link',{attrs:{"to":"/news"}},[_vm._v("News & Insights")])],1),_c('li',{staticStyle:{"animation-delay":"0.7s"}},[_c('router-link',{attrs:{"to":"/reports-and-resources"}},[_vm._v("Reports & Resources")])],1),_c('li',{staticStyle:{"animation-delay":"0.8s"}},[_c('router-link',{attrs:{"to":"/contact"}},[_vm._v("Contact")])],1)])])]),_c('nav',{ref:"subMenu",staticClass:"subMenu",class:this.$route.path.split('/')[1] === 'about-us' ||
            this.$route.path.split('/')[1] === 'grantmaking' ||
            this.$route.path.split('/')[1] === 'grants' ||
            this.$route.path.split('/')[1] === 'small-grants' ||
            this.$route.path.split('/')[1] === 'event-space' ||
            this.$route.path.split('/')[1] === 'fivefields'
              ? 'is-visible'
              : null,style:([_vm.navStyles, _vm.navTransform])},[_c('div',{staticClass:"subMenu__back",on:{"click":function($event){_vm.toggleSubMenu();
              _vm.aboutus = false;
              _vm.grantmaking = false;}}},[_c('IconArrowMenu',{attrs:{"direction":"back"}}),_c('span',[_vm._v("Back to menu")])],1),_c('ul',{class:[
              'subMenu__list',
              this.$route.path.split('/')[1] === 'about-us'
                ? 'is-visible'
                : null,
              this.aboutus === true ? 'is-active' : null
            ]},[_vm._m(0),_c('li',[_c('button',{on:{"click":function($event){return _vm.scrollToSection('where-we-fund')}}},[_vm._v(" Where we fund ")])]),_c('li',[_c('button',{on:{"click":function($event){return _vm.scrollToSection('what-we-fund')}}},[_vm._v(" What we fund ")])]),_c('li',[_c('button',{on:{"click":function($event){return _vm.scrollToSection('trustees')}}},[_vm._v(" Our Trustees ")])]),_c('li',[_c('button',{on:{"click":function($event){return _vm.scrollToSection('meet-the-team')}}},[_vm._v(" Our Team ")])])]),_c('ul',{class:[
              'subMenu__list',
              this.$route.path.split('/')[1] === 'grantmaking' ||
              this.$route.path.split('/')[1] === 'grants' ||
              this.$route.path.split('/')[1] === 'small-grants' ||
              this.$route.path.split('/')[1] === 'fivefields' ||
              this.$route.path.split('/')[1] === 'event-space'
                ? 'is-visible'
                : null,
              this.grantmaking === true ? 'is-active' : null
            ]},[_c('li',[_c('router-link',{attrs:{"to":"/grantmaking"}},[_vm._v("Grantmaking")])],1),_c('li',[_c('router-link',{attrs:{"to":"/grants"}},[_vm._v("Grants")])],1),_c('li',[_c('router-link',{attrs:{"to":"/small-grants"}},[_vm._v("Small Grants")])],1),_c('li',[_c('router-link',{attrs:{"to":"/fivefields"}},[_vm._v("Fivefields")])],1),_c('li',[_c('router-link',{attrs:{"to":"/event-space"}},[_vm._v("Meeting & Event Space")])],1)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/about-us"}},[_vm._v("About us")])])
}]

export { render, staticRenderFns }